.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

a,
a:visited {
  color: #e8e8e8;
  text-decoration: none;
}

a:hover {
  color: #bbbbbbda;
}

h1,
p {
  padding: 0px;
  margin: 0px;
}

h1 {
  font-size: 4em;
}
